body{
  overflow: hidden;
}
  #gameContainer {
    width: 100%;
    height: 90vh;
    bottom: 0;
    overflow: hidden;
    position: absolute;
    z-index: 0;
  }
  

  .name{
    user-select: 'none';
  }
  .object {
    background: url('../assets/images/money.png') no-repeat center center;
    background-size: contain;
    position: absolute;
    width: 50px;
    height: 60px;
    user-select: 'none';
    z-index: -1;
  }
  
  @keyframes floatUp {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-50px);
    }
  }
  
  .coin-button {
    transition: transform 0.1s ease;
    user-select: 'none';
  }
  
  .coin-button:active {
    transform: scale(0.99);
    user-select: 'none';
  }
  .floating-text {
    user-select: 'none';
    position: absolute;
    animation: floatUp 2s forwards;
    pointer-events: none;

    font-weight: 400;
    font-size: 25px;
    color: rgba(0,0,0,0.4)
    
  }
  .text-sm {
    user-select: 'none';
    font-size: 14px;
    color: rgba(0,0,0,0.4)
  }
  .reward-float{
    user-select: 'none';
    color: rgba(0,0,0,0.8);
    z-index: 999;
  }
