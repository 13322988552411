@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.fade-in:nth-child(1) {
    animation: fadeIn 0.2s ease-in-out;
}

.fade-in:nth-child(2) {
    animation: fadeIn 0.4s ease-in-out;
}

.fade-in:nth-child(3) {
    animation: fadeIn 0.6s ease-in-out;
}
.fade-in:nth-child(4) {
    animation: fadeIn 0.8s ease-in-out;
}
.fade-in:nth-child(5) {
    animation: fadeIn 1s ease-in-out;
}
.fade-in:nth-child(6) {
    animation: fadeIn 1.2s ease-in-out;
}
.fade-in:nth-child(7) {
    animation: fadeIn 1.5s ease-in-out;
}

.blob {

    box-shadow: 0 0 0 0 rgba(0, 255, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
    }
}

/* Add more rules as needed for additional children */