@font-face {
  font-family: 'HelveticaNeue';
  src: url('../assets/fonts/HelveticaNeue-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('../assets/fonts/HelveticaNeueCyr-Roman.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('../assets/fonts/HelveticaNeueCyr-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Regular'),
      url('../assets/fonts/Poppins-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-SemiBold'),
      url('../assets/fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'open-sans-semibold';
  src: local('open-sans-semibold'),
      url('../assets/fonts/open-sans-semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
